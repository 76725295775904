import React from 'react'
import { graphql } from 'gatsby'
import * as styles from './news-post.module.scss'
import { tr } from 'js/translations.js'

import Banner from 'components/Banner'
import BgLink from 'components/BgLink'
import Breadcrumb from 'components/Breadcrumb'
import ContentfulRichTextPost from 'components/ContentfulRichTextPost'
import Inview from 'components/Inview'
import Layout from 'components/Layout'
import Seo from 'components/Seo'

const Page = ({ data }) => {
  const locale = process.env.GATSBY_LOCALE
  let post = data['post']
  const page = data['page'].nodes[0]
  const breadcrumb = [
    {
      text: tr('HOME', locale),
      link: '/',
    },
    {
      text: page.title,
      link: '/news/',
    },
    {
      text: post.title,
    },
  ]
  const postContent = post.post

  post.seo = {
    title: post.title,
    description: {
      description: post.excerpt?.excerpt,
    },
  }

  return (
    <Layout>
      <div>
        <Seo page={post} locale={locale} />
        <Banner image={page.banner} locale={locale} />
        <Breadcrumb items={breadcrumb} />

        <div className='wrapper pad-sides'>
          <div className='inner'>
            <Inview>
              <div
                className={`
                  ${styles.intro}
                  fade-in slower
                `}
              >
                <h4>
                  {post.postDate}
                  {post.category && <span>{post.category.title}</span>}
                </h4>
                <h1>{post.title}</h1>
              </div>
            </Inview>
            <div className={styles.post}>
              {postContent && <ContentfulRichTextPost content={postContent} />}
              <BgLink to='/news/' text={`« ${tr('BACK_TO_ALL', locale)}`} />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Page

export const pageQuery = graphql`
  query NewsPostBySlug($slug: String!) {
    post: contentfulNewsPost(slug: { eq: $slug }) {
      title
      postDate(formatString: "YYYY-M-DD")
      pinToTop
      category {
        title
      }
      excerpt {
        excerpt
      }
      post {
        raw
        references {
          ... on ContentfulAsset {
            contentful_id
            __typename
            ...GatsbyImage
          }
        }
      }
    }
    page: allContentfulNewsPage {
      nodes {
        title
        banner {
          ...GatsbyImage
        }
        introText {
          raw
        }
      }
    }
  }
`
